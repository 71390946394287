var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Card', [_c('Alert', {
    attrs: {
      "type": "warning",
      "show-icon": ""
    }
  }, [_vm._v(" 说明：基于Uniapp开发的配套XBoot App，后端正在XBoot中开发 "), _c('a', {
    attrs: {
      "href": "http://xpay.exrick.cn/pay?xboot",
      "target": "_blank"
    }
  }, [_vm._v("立即获取完整版")])]), _c('div', {
    staticClass: "uniapp"
  }, [_vm._v("H5演示版本请等待上线！前端请等待开源！敬请关注！")]), _c('div', {
    staticClass: "uniapp-imgs"
  }, [_c('img', {
    attrs: {
      "src": "https://ooo.0o0.ooo/2020/04/27/rYgLKIQGoB1f2JF.jpg"
    }
  }), _c('img', {
    attrs: {
      "src": "https://ooo.0o0.ooo/2020/04/27/WlLwqRNcsEQuT5Y.jpg"
    }
  }), _c('img', {
    attrs: {
      "src": "https://ooo.0o0.ooo/2020/04/27/GeaVHTmcYdQNis7.jpg"
    }
  }), _c('img', {
    attrs: {
      "src": "https://ooo.0o0.ooo/2020/04/27/LKcgJBdmlpG9iOj.jpg"
    }
  })]), _c('div', {
    staticClass: "uniapp-imgs",
    staticStyle: {
      "margin": "20px 0"
    }
  }, [_c('img', {
    attrs: {
      "src": "https://ooo.0o0.ooo/2020/04/27/7AkmNaqTyIo1Q2v.jpg"
    }
  }), _c('img', {
    attrs: {
      "src": "https://ooo.0o0.ooo/2020/04/27/1DA4JnBlxgNFY5v.jpg"
    }
  }), _c('img', {
    attrs: {
      "src": "https://ooo.0o0.ooo/2020/04/27/BXga1m3LQDSTdGE.jpg"
    }
  }), _c('img', {
    attrs: {
      "src": "https://ooo.0o0.ooo/2020/04/27/5KRuGiDwpSQbo4h.jpg"
    }
  })])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };